import { useTheme } from "@emotion/react";
import { Box, Chip, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MessageInput from "./input/messageInput";
import PulseLoader from "react-spinners/PulseLoader";
import Header from "./header/Header";

const Home = ({
  categories,
  handleItemClick,
  isLoading,
  userLang,
  setUserLang,
  setIsInputFocused,
  isItemLoading,
}) => {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const isLandscape = useMediaQuery("(orientation: landscape)");

  const handleSendInHome = (message, voicelg) => {
    navigate("/chat", {
      state: { initialMessage: message, voiceLang: voicelg },
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isMediumDown ? "space-between" : "space-around",
        alignItems: "center",
        flexDirection: "column",
        height: isMediumDown ? "" : "100vh",
        flexGrow: 1,
        gap: isLandscape ? "0" : "8%",
        padding: isMediumDown ? "0px 8px 56px" : "3%",
        pointerEvents: isItemLoading ? "none" : "auto",
      }}
    >
      {isItemLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <PulseLoader color="white" size={15} />
        </Box>
      )}
      {isMediumDown && <Header isMediumDown={isMediumDown} page={"home"} />}

      <div
        style={{
          display: "flex",
          gap: "4%",
          flexDirection: "column",
          padding: isLandscape ? "0%" : isMediumDown ? "6%" : "",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
          marginTop: isMediumDown ? (isLandscape ? "8%" : "25%") : "",
        }}
      >
        {categories.map((category) => (
          <Box
            key={category.title}
            sx={{
              width: "100%",
              mb: isMediumDown ? (isLandscape ? "0%" : "10px") : 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Typography
              sx={{
                mb: isMediumDown ? "8px" : 1,
                fontSize: isMediumDown ? "14px" : "16px",
                fontWeight: "bolder",
                textAlign: "center",
                color: "#263238",
              }}
            >
              {category.title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: isMediumDown ? "5px" : 1,
                justifyContent: "center",
              }}
            >
              {category.items.map((item) => (
                <Chip
                  key={item.path}
                  label={item.shortLabel}
                  onClick={() => handleItemClick(item, navigate)}
                  sx={{
                    backgroundColor: "inherit",
                    border: "1px solid #212121",
                    color: "#212121",
                    borderRadius: "16px",
                    padding: "8px",
                    width: "fit-content",
                    cursor: "pointer",
                    fontSize: "13px",
                    "&:hover": {
                      borderColor: "#263238",
                      color: "#263238",
                      backgroundColor: "inherit",
                      fontWeight: "bolder",
                    },
                  }}
                />
              ))}
            </Box>
          </Box>
        ))}
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "sticky",
          bottom: "0",
          padding: isMediumDown ? "0" : "",
          width: "100%",
        }}
      >
        <MessageInput
          handleSend={handleSendInHome}
          userLang={userLang}
          setUserLang={setUserLang}
          isLoading={isLoading}
          setIsInputFocused={setIsInputFocused}
        />
      </Box>
    </Box>
  );
};

export default Home;
