import React from "react";
import JockeyLogo from "../assets/jockey_logo.png";
import ApparelLogo from "../assets/ApparelLogo.png"

const Logo = ({ onClick }) => (
  <img
    fetchpriority="high"
    width={350}
    src={ApparelLogo}
    alt="Jockey Logo"
    sizes="(max-width: 600px) 100vw, 500px"
    style={{ cursor: onClick ? "pointer" : "default" }}
    onClick={onClick}
  />
);

export default Logo;
