const { useTheme } = require("@emotion/react");
const { useMediaQuery } = require("@mui/material");
const { useState } = require("react");

const Suggestions = ({ handleSend }) => {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const isLandscape = useMediaQuery("(orientation: landscape)");

  const sampleQuestions = require("./sampleQuestions");
  const sampleSQLQuestions = require("./sampleSQLQuestions");

  const [sampleIndex, setSampleIndex] = useState(0); 
  const [sqlIndex, setSQLIndex] = useState(0); 
  const [useSample, setUseSample] = useState(true); 
  const getNextQuestion = () => {
    let question;

    if (useSample) {
      question = sampleQuestions[sampleIndex];
      setSampleIndex((prevIndex) => (prevIndex + 1) % sampleQuestions.length);
    } else {
      question = sampleSQLQuestions[sqlIndex];
      setSQLIndex((prevIndex) => (prevIndex + 1) % sampleSQLQuestions.length);
    }

    setUseSample((prev) => !prev);

    return question;
  };

  const handleRandomQuestion = () => {
    const randomQuestion = getNextQuestion();
    handleSend(randomQuestion);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: isMediumDown ? (isLandscape ? "" : "4%") : "5%",
        alignItems: "center",
        marginLeft: isMediumDown ? "" : "10%",
      }}
    >
      <div
        style={{
          border: "0.5px solid #212121",
          borderRadius: "5px",
          padding: "10px",
          color: "#212121",
          fontSize: isMediumDown ? "12px" : "16px",
          cursor: "pointer",
          fontWeight: 500,
          backgroundColor: "rgb(255 255 255 / 54%)",
        }}
        onClick={handleRandomQuestion}
      >
        Check out our sample questions!!
      </div>
    </div>
  );
};

export default Suggestions;
