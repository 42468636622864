import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import NavLink from "../atoms/NavLink";
import Logo from "../atoms/Logo";
import ActionButton from "../atoms/ActionButton";
import NoviroLogo from "../assets/Noviro.png";

const DesktopNav = ({ isAuthenticated, onLogoClick, onButtonClick }) => (
  <AppBar
    position="fixed"
    sx={{
      height: "10%",
      boxShadow: "none",
      zIndex: 1300,
      background: "rgb(221 195 164)",
    }}
  >
    <Toolbar
      disableGutters
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        height: "100%",
        gap: "5%",
        maxWidth: "100%",
        padding: "0 2%",
      }}
    >
      {/* Navigation Links */}
      <div
        style={{
          display: "flex",
          // gap: "7%",
          width: "20%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <NavLink to="/home" label="HOME" />
        <NavLink to="/chat" label="CHAT" />
        <NavLink to="/screens" label="SCREENS" />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Logo onClick={onLogoClick} />
        {/* <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            fontFamily: "Roboto",
            color: "#555555",
          }}
        >
          AI Agent by channelco.ai
        </div> */}
      </div>
      {/* Login/Logout Button */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "25%",
        }}
      >
        <img src={NoviroLogo} alt="Noviro Logo" width={"100px"} />
        <ActionButton
          label={isAuthenticated ? "LOGOUT" : "LOGIN"}
          onClick={onButtonClick}
        />
      </div>
    </Toolbar>
  </AppBar>
);

export default DesktopNav;
