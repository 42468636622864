const sampleSQLQuestions =[
"What is the total revenue generated from all 'Delivered' orders?",
"Which products have the highest total sales amount?",
"What is the total quantity of products moved in each warehouse?" ,
"How many orders are still pending and need to be processed for shipment?",
"List all shipments that are still 'In Transit' ?",
"Which customers placed the most orders in last year?",
"Which products contributed the most revenue last quarter of 2024?",
"Provide a month-wise sales summary of all cities for last year ?",
"Which customers placed the most orders in last year?",
"What are the total sales of MV57?"
];
module.exports = sampleSQLQuestions;
