import React from "react";
import { useNavigate } from "react-router-dom";
import JockeyLogo from "../../assets/jockey_logo.png";
import ApparelLogo2 from "../../assets/ApparelLogo2.png"
import NoviroLogo from "../../assets/Noviro.png";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import Suggestions from "../suggestions/Suggestions";

const Header = ({ isMediumDown, page, handleSend }) => {
  const navigate = useNavigate();
  const isLandscape = useMediaQuery("(orientation: landscape)");
  const theme = useTheme();

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    width:
      page === "home" || page === "login"
        ? isLandscape
          ? "98%"
          : "95%"
        : "100%",
    alignItems: "center",
    padding: isLandscape ? "1%" : "3% 3% 5%",
    position: isLandscape && page === "table" ? "" : "fixed",
    top: 0,
    left: 0,
    background: "rgb(221 195 164)",
    zIndex: 1000,
  };

  const textContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const textStyle = {
    fontSize: "16px",
    fontWeight: "500",
    color: "#555555",
  };

  const handleLogoClick = () => {
    if (page === "screensItem") {
      navigate(-1);
    } else {
      navigate("/home");
    }
  };

  return (
    <div style={containerStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <img
          fetchpriority="high"
          width={"150"}
          src={ApparelLogo2}
          alt="Jockey Logo"
          sizes="(max-width: 500px) 100vw, 500px"
          style={{ cursor: "pointer" }}
          onClick={handleLogoClick}
        />
        {/* <div style={textContainerStyle}>
          <span style={textStyle}>AI Agent</span>
          <span style={textStyle}>by channelco.ai</span>
        </div> */}
        <img src={NoviroLogo} alt="Noviro Logo" width={"100px"} />
      </div>
      {page === "chat" && <Suggestions handleSend={handleSend} />}
    </div>
  );
};

export default Header;
